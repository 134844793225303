<template>
  <ClientOnly>
    <label :for="randomId" class="cursor-pointer leading-5" :class="{'flex mb-1':!like,white, 'items-start':itemsStart, 'items-center':!itemsStart }" role="presentation" @click="$emit('click')">
      <input :id="randomId" v-model="model" type="checkbox" :value="value" class="opacity-0 absolute h-8 w-2 cursor-pointer">
      <Icon :name="icon" class="mr-1" :red="red" :green="green" :white="white" />
      <span :class="{'text-red':red}">{{ label }}</span>
      <slot />
    </label>
  </ClientOnly>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    modelValue: {
      type: [Array, Boolean],
      required: true
    },
    value: {
      type: [Boolean, String],
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    red: Boolean,
    green: Boolean,
    white: Boolean,
    like: Boolean,
    itemsStart: Boolean
  },
  emits: ['update:modelValue', 'click'],
  computed: {
    model: {
      get () {
        return this.modelValue
      },
      set (value:(Array<string> | boolean)) {
        this.$emit('update:modelValue', value)
      }
    },
    isChecked () {
      return Array.isArray(this.modelValue) ? this.modelValue.includes(this.value) : this.modelValue
    },
    icon () {
      return `${this.like ? 'like' : 'checkbox'}${this.isChecked ? '-checked' : ''}`
    },
    randomId () {
      return Math.random().toString(36).substring(7)
    }
  }
})
</script>

<style lang="postcss" scoped>
.white{
  @apply text-white;
}
</style>
